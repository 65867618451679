import branch from 'branch-sdk';

import { getListProductsInfo, getCombinationInfo } from '../helpers/product.js';
const $get = require('lodash.get');

const BranchWebSDK = {
  payload: {},
  eventName: '',
  addToCart(product, qty) {
    const combinationInfo = getCombinationInfo(product);
    const contentId = $get(
      product,
      'default_combination.tracker_combination_name',
      '',
    );
    const branchCustomData = {};
    branchCustomData.Brand = $get(product, 'brand.name', '');
    branchCustomData.Category_Parent = $get(product, 'categories[0].name', '');
    branchCustomData.Category_Child = $get(product, 'categories[1].name', '');
    branchCustomData.Category_GrandChild = $get(
      product,
      'categories[2].name',
      '',
    );
    branchCustomData.Category = $get(product, 'default_category.name', '');
    branchCustomData.Id = String(product.id);
    branchCustomData.Product_Name = product.name ? product.name : '';
    branchCustomData.Price = String(
      $get(product, 'default_combination.price_after_discount', ''),
    );
    branchCustomData.Combination_Name = combinationInfo.combinationName;
    branchCustomData.Quantity = String(qty);

    branchCustomData.Contents = [
      {
        id: contentId.toLowerCase(),
        quantity: qty,
      },
    ];
    const event_and_custom_data = branchCustomData;

    branch.logEvent('ADD_TO_CART', event_and_custom_data);

    this.eventName = 'addToCart';
    return this;
  },
  purchase(order) {
    try {
      const extractProducts = getListProductsInfo(order.cartDetail.products);
      const branchCustomData = {};
      branchCustomData.Brand = extractProducts.brands.join(', ');
      branchCustomData.Revenue = String(order?.total_paid);
      branchCustomData.Category_Parent =
        extractProducts.categoriesParent.join(', ');
      branchCustomData.Category_Child =
        extractProducts.categoriesChild.join(', ');
      branchCustomData.Category_GrandChild =
        extractProducts.categoriesGrandChild.join(', ');
      branchCustomData.Product_Id = extractProducts.productsId.join(', ');
      branchCustomData.Order_Id = String(order._id);
      branchCustomData.Product_Name = extractProducts.productsName.join(', ');
      branchCustomData.Combination_Name =
        extractProducts.combinationsName.join(', ');
      branchCustomData.Quantity = String(order?.total_quantity);
      branchCustomData.Content_type = 'product';
      branchCustomData.Amount_Of_Purchase = String(order?.total_paid);
      branchCustomData.Sub_Total = String(order?.total_paid);
      branchCustomData.Country = $get(
        order,
        'cartDetail.address.country.name',
        '',
      );
      branchCustomData.Shipping = String(order?.total_shipping);
      branchCustomData.Payment_Method = order?.payment_method?.name
        ? String(order.payment_method.name)
        : '';
      branchCustomData.Voucher_Code = order.cartDetail?.voucher_code;
      const event_and_custom_data = branchCustomData;

      branch.logEvent('PURCHASE', event_and_custom_data);
    } catch (err) {
      console.log(err);
    }
  },

  viewContent(product) {
    const combinationInfo = getCombinationInfo(product);
    const contentId = $get(
      product,
      'default_combination.tracker_combination_name',
      '',
    );
    const branchCustomData = {};
    branchCustomData.Brand = $get(product, 'brand.name', '');
    branchCustomData.Category_Parent = $get(product, 'categories[0].name', '');
    branchCustomData.Category_Child = $get(product, 'categories[1].name', '');
    branchCustomData.Category_GrandChild = $get(
      product,
      'categories[2].name',
      '',
    );
    branchCustomData.Category = $get(product, 'default_category.name', '');
    branchCustomData.Id = String(product.id);
    branchCustomData.Product_Name = product.name ? product.name : '';
    branchCustomData.Price = String(
      $get(product, 'default_combination.price_after_discount', ''),
    );
    branchCustomData.Combination_Name = combinationInfo.combinationName;
    branchCustomData.Content_id = contentId;

    const event_and_custom_data = branchCustomData;

    branch.logEvent('VIEW_CONTENT', event_and_custom_data);

    this.eventName = 'viewContent';
    return this;
  },

  search(text, searchResultCount) {
    const branchCustomData = {};
    branchCustomData.SearchKeyword = text;
    branchCustomData.SearchResult =
      searchResultCount.payload.data > 0 ? true : false;

    const event_and_custom_data = branchCustomData;
    branch.logEvent('SEARCH', event_and_custom_data);

    this.eventName = 'search';
    return this;
  },
};

export default BranchWebSDK;
