const $get = require('lodash.get');
import { ucfirst } from '~global/helpers';
function extractCombination(attributes) {
  let combinationName = '';
  let combinationId = '';
  let attributeSlug = '';
  for (const attribute in attributes) {
    if (
      attribute !== 'non_specify' &&
      attributes[attribute] &&
      attributes[attribute].name
    ) {
      const attributeName = attributes[attribute].name
        .replace(/  +/gi, '')
        .replace(/-/gi, '_');
      if (attributeSlug) {
        attributeSlug += '_';
      }

      if (combinationName) {
        combinationName += ' - ';
      }
      combinationName += `${ucfirst(attribute)}: ${attributeName}`;
      attributeSlug += `${attributeName.replace(/\s/gi, '_')}`;
      combinationId = attributes[attribute].id;
    }
  }
  return {
    combinationName,
    attributeSlug,
    combinationId,
  };
}

export function getCombinationInfo(product) {
  let combinationInfo;
  if (product.default_combination && product.default_combination.attributes) {
    combinationInfo = extractCombination(
      product.default_combination.attributes,
    );
  } else if (
    product.combinations &&
    Array.isArray(product.combinations) &&
    product.combinations[0]
  ) {
    combinationInfo = extractCombination(product.combinations[0].attributes);
  } else if (
    product.combination &&
    typeof product.combination === 'object' &&
    Object.keys(product.combination).length
  ) {
    combinationInfo = extractCombination(product.combination.attributes);
  } else {
    combinationInfo = {
      combinationName: '',
      attributeSlug: '',
      combinationId: '',
    };
  }

  return combinationInfo;
}

export function getListProductsInfo(products) {
  const contentsId = [];
  const contents = [];
  const eanNo = [];
  const referenceCode = [];
  const categoriesParent = [];
  const categoriesChild = [];
  const categoriesGrandChild = [];
  const productsName = [];
  const productsId = [];
  const brands = [];
  const combinationsName = [];
  const quantities = [];
  const classifications = [];
  const unitpPrices = [];
  const unitpPriceAfterDiscounts = [];
  const priceRulesIds = [];
  const priceRulesNames = [];
  const discountfromVouchers = [];

  if (products && Array.isArray(products)) {
    for (let i = 0; i < products.length; ++i) {
      const product = products[i];
      if (product?.detail?.combination?.attributes) {
        const attributes = product?.detail?.combination?.attributes;
        const combinationInfo = extractCombination(attributes);
        const contentId = $get(product, 'tracker_combination_name', '');

        const categoryParent = $get(product.detail, 'categories[0].name', '');
        const categoryChild = $get(product.detail, 'categories[1].name', '');
        const categoryGrandChild = $get(
          product.detail,
          'categories[2].name',
          '',
        );
        const quantity = $get(product, 'quantity', 1);
        const content = {
          id: contentId.toLowerCase(),
          quantity: quantity,
        };
        const productName = $get(product, 'detail.name', '');
        const brand = $get(product, 'detail.brand.name', '');

        const unitpPrice = $get(product, 'unit_price', 0);
        const unitpPriceAfterDiscount = $get(
          product,
          'unit_price_after_discount',
          0,
        );

        const priceRulesId = $get(product, 'product_price_rule_id', '');
        const priceRulesName = $get(product, 'product_price_rule_name', '');
        const discountfromVoucher = $get(product, 'discount_from_voucher', '');

        const classification = $get(product, 'classification', '');
        const productId = product.detail.id;
        const combinationName = combinationInfo.combinationName;
        categoriesParent.push(categoryParent);
        categoriesChild.push(categoryChild);
        categoriesGrandChild.push(categoryGrandChild);
        contentsId.push(contentId.toLowerCase());
        contents.push(content);
        productsName.push(productName);
        brands.push(brand);
        quantities.push(quantity);
        productsId.push(productId);
        combinationsName.push(combinationName);
        classifications.push(classification);
        unitpPrices.push(unitpPrice);
        unitpPriceAfterDiscounts.push(unitpPriceAfterDiscount);
        priceRulesIds.push(priceRulesId);
        priceRulesNames.push(priceRulesName);
        discountfromVouchers.push(discountfromVoucher);
      } else if (
        product.combination &&
        Object.keys(product.combination).length
      ) {
        const combinationInfo = extractCombination(product.combination);
        const contentId = $get(product, 'tracker_combination_name', '');
        const categoryParent = $get(product, 'categories[0].name', '');
        const categoryChild = $get(product, 'categories[1].name', '');
        const categoryGrandChild = $get(product, 'categories[2].name', '');
        const quantity = $get(product, 'quantity', 1);
        const content = {
          id: contentId.toLowerCase(),
          quantity: quantity,
        };
        const productName = $get(product, 'name', '');

        const classification = $get(product, 'classification', '');
        const unitpPrice = $get(product, 'unit_price', 0);
        const unitpPriceAfterDiscount = $get(
          product,
          'unit_price_after_discount',
          0,
        );
        const priceRulesId = $get(product, 'product_price_rule_id', '');
        const priceRulesName = $get(product, 'product_price_rule_name', '');
        const discountfromVoucher = $get(product, 'discount_from_voucher', '');
        const brand = $get(product, 'brand.name', '');
        const productId = product.id;
        const combinationName = combinationInfo.combinationName;
        categoriesParent.push(categoryParent);
        categoriesChild.push(categoryChild);
        categoriesGrandChild.push(categoryGrandChild);
        contentsId.push(contentId.toLowerCase());
        contents.push(content);
        productsName.push(productName);
        brands.push(brand);
        quantities.push(quantity);
        productsId.push(productId);
        combinationsName.push(combinationName);
        classifications.push(classification);
        unitpPrices.push(unitpPrice);
        unitpPriceAfterDiscounts.push(unitpPriceAfterDiscount);
        priceRulesIds.push(priceRulesId);
        priceRulesNames.push(priceRulesName);
        discountfromVouchers.push(discountfromVoucher);
      }
      eanNo.push(product.ean_no);
      referenceCode.push(product.reference_code);
    }
  }

  return {
    contentsId,
    categoriesParent,
    categoriesChild,
    categoriesGrandChild,
    contents,
    productsName,
    brands,
    quantities,
    productsId,
    combinationsName,
    eanNo,
    referenceCode,
    classifications,
    unitpPriceAfterDiscounts,
    unitpPrices,
    priceRulesIds,
    priceRulesNames,
    discountfromVouchers,
    totalQuantity: quantities.reduce((a, b) => {
      return a + b;
    }, 0),
  };
}

export function getSKU(product) {
  let result = '';

  if (product.sku) {
    result = product.sku;
  } else {
    if (product.combination) {
      if (product.combination.reference) {
        result = product.combination.reference;
      }
    }
  }

  return result;
}

export function generateCombinationName(combination, tracker = false) {
  if (!combination || !combination.attributes) {
    return '';
  }
  combination = JSON.parse(JSON.stringify(combination));
  let variant = '';
  Object.keys(combination.attributes || {}).forEach((attr, index) => {
    // add comma to string, except if only one, or last one
    if (index > 0 && index < Object.keys(combination.attributes).length) {
      variant = `${variant}, `;
    }
    if (tracker) {
      variant = `${variant}${
        attr == 'non_specify' ? '' : combination.attributes[attr].name
      }`;
    } else if (attr === 'non_specify') {
      variant = `${variant}${attr.charAt(0).toUpperCase()}${attr.slice(1)}: ${
        combination.attributes[attr].name
      }`;
      variant = variant.replace('_', '-');
    } else {
      variant = `${variant}${attr.charAt(0).toUpperCase()}${attr.slice(1)}: ${
        combination.attributes[attr].name
      }`;
    }
  });
  if (tracker) {
    return slugify({
      value: variant,
      separator: '_',
    });
  }
  return variant;
}

function slugify(data = {}) {
  if (data && typeof data == 'object' && data.value) {
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕßśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnooooooprssstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return String(data.value || '')
      .toLowerCase()
      .replace(
        // eslint-disable-next-line no-useless-escape
        /\s+/g,
        '-',
      ) // Replace spaces with -
      .replace(p, (c) => b.charAt((a || '').indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(
        // eslint-disable-next-line no-useless-escape
        /[^\w\-]+/g,
        '',
      ) // Remove all non-word characters
      .replace(
        // eslint-disable-next-line no-useless-escape
        /\-\-+/g,
        '-',
      ) // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
      .replace(/-/g, data.separator ? data.separator : '-');
  } else {
    return '';
  }
}
