/* eslint-disable no-undef */
import ENV from '../env.js';
import { isNative } from '~global/screens.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Sociolla } from '~api/sociolla';
const FbConversion = {
  payload: {},
  eventName: '',
  eventID: '',
  isLogin: false,
  userData: null,
  getEventID() {
    return this.eventID;
  },
  async isReady() {
    const userData = JSON.parse(await AsyncStorage.getItem('userData'));
    this.userData = userData ? userData.user : null;
    this.isLogin = this.userData ? true : false;
    this.generateUUID();
  },
  generateUUID() {
    let currentDateTime = new Date().getTime();
    let newGuid = '';

    if (
      !isNative &&
      typeof window.performance !== 'undefined' &&
      typeof window.performance.now === 'function'
    ) {
      currentDateTime += performance.now(); //use high-precision timer if available
    }

    newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (currentDateTime + Math.random() * 16) % 16 | 0;
        currentDateTime = Math.floor(currentDateTime / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
    this.eventID = newGuid;
  },
  fbTracking({ name, data }) {
    this.isReady();
    if (typeof fbq !== 'undefined') {
      fbq('track', name, data, { eventID: this.eventID });
    }

    const payload = {
      event_name: name,
      event_id: this.eventID,
      source_url: window.location.href,
      user: {},
    };

    if (data && Object.keys(data).length) {
      payload.custom_data = data;
    }

    if (this.isLogin) {
      payload.user.email = this.userData.email_lower
        ? this.userData.email_lower
        : this.userData.email;
    }

    if (!isNative) {
      const fbclid = new URLSearchParams(window.location.search);
      if (this.getCookie('_fbp')) {
        payload.user.fbp = this.getCookie('_fbp');
      }

      if (fbclid.get('fbclid')) {
        payload.user.fbc = fbclid.get('fbclid');
      }
    }

    if (this.eventID) {
      Sociolla.fbConversion(payload);
    }
  },
  getCookie(cookieName) {
    if (!isNative) {
      const name = cookieName + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    }
    return null;
  },
  debug() {
    if (ENV.debug) {
      if (this.payload && Object.keys(this.payload).length) {
        console.log(`-----Event Fb Conversion ${this.eventName} Data-----`);
        for (const key in this.payload) {
          console.log(`${key} => ${this.payload[key]}`);
        }
        console.log('-----EndDebug-----');
      }
    }
    return this;
  },
};

export default FbConversion;
